import React, { useState, useEffect } from "react";
import { FaLinkedinIn, FaInstagram, FaVimeo, FaImdb, FaFilm } from "react-icons/fa";

const Footer = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const WOWDELAY = "200ms";

  return (
    <footer className="rn-footer-area bg-color-black pt--60 pb--60">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay={WOWDELAY}
              data-wow-duration="0.1s"
            >
              <div className="ft-info">
                <p>@2021 Matthias Neumayer </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay={WOWDELAY}
              data-wow-duration="0.2s"
            >
              <div className="ft-info">
                <p>
                  <a href="/imprint">Imprint</a>
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay={WOWDELAY}
              data-wow-duration="0.4s"
            >
              <div className="ft-info">
                <p>hello[at]matthiasneumayer.com</p>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay={WOWDELAY}
              data-wow-duration="0.6s"
            >
              <div className="ft-info">
                <ul className="social-share">
                  <li>
                    <a href="https://www.linkedin.com/in/matthiasneumayer/">
                      <FaLinkedinIn />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/matthias_neumayer/">
                      <FaInstagram />
                    </a>
                  </li>

                  <li>
                    <a href="https://vimeo.com/matthiasneumayer">
                      <FaVimeo />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.crew-united.com/de/profil/editMember.asp">
                      <FaFilm />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.imdb.com/name/nm3380415/">
                      <FaImdb />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back-to-top">
        <div className="backtop" onClick={handleClick} role="button" tabIndex={0} onKeyDown={() => {}}>
          <span className="top"></span>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
